import Vue from 'vue';
import MainLayout from '@/layouts/MainLayout';
import Pricing from '@/components/Home/Pricing';
import Services from '@/components/Home/Services';
import Slider from '@/components/Home/Slider';
import AskedQuestions from '@/components/Home/AskedQuestions';

export default Vue.extend({
  name: 'Home',

  components: { MainLayout, Pricing, Services, Slider, AskedQuestions },
});
